var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Sales Order" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-form",
                            {
                              attrs: {
                                layout: "vertical",
                                form: _vm.form,
                                "label-col": { span: 10 },
                                "wrapper-col": { span: 14 }
                              }
                            },
                            [
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.salesOrderNumber
                                                .label
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value:
                                                    _vm.formRules
                                                      .salesOrderNumber
                                                      .decorator,
                                                  expression:
                                                    "formRules.salesOrderNumber.decorator"
                                                }
                                              ],
                                              attrs: {
                                                name:
                                                  _vm.formRules.salesOrderNumber
                                                    .name,
                                                placeholder: _vm.$t(
                                                  _vm.formRules.salesOrderNumber
                                                    .placeholder
                                                ),
                                                showSearch: true,
                                                "option-filter-prop":
                                                  "children",
                                                "filter-option":
                                                  _vm.filterOption,
                                                loading: _vm.loadingSales,
                                                allowClear: true
                                              },
                                              on: {
                                                search: function(value) {
                                                  return _vm.getListSalesOrderHeaderOnly(
                                                    value
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(
                                              _vm.dataListSalesOrder,
                                              function(data, index) {
                                                return _c(
                                                  "a-select-option",
                                                  {
                                                    key: index,
                                                    attrs: { value: data.id }
                                                  },
                                                  [
                                                    _c(
                                                      "a-tooltip",
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "title" },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "" +
                                                                    data.salesOrderNo
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              "" +
                                                                data.salesOrderNo
                                                            ) +
                                                            " "
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.date.label
                                            )
                                          }
                                        },
                                        [
                                          _c("a-range-picker", {
                                            staticClass: "w-100",
                                            attrs: {
                                              ranges: {
                                                Today: [
                                                  _vm.moment(),
                                                  _vm.moment()
                                                ],
                                                "This Month": [
                                                  _vm.moment(),
                                                  _vm.moment().endOf("month")
                                                ]
                                              },
                                              format: _vm.DEFAULT_DATE_FORMAT,
                                              type: "date"
                                            },
                                            on: {
                                              change: _vm.onRangePickerChange
                                            },
                                            model: {
                                              value: _vm.modelForm.date,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.modelForm,
                                                  "date",
                                                  $$v
                                                )
                                              },
                                              expression: "modelForm.date"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.customerName.label
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value:
                                                    _vm.formRules.customerName
                                                      .decorator,
                                                  expression:
                                                    "formRules.customerName.decorator"
                                                }
                                              ],
                                              attrs: {
                                                name:
                                                  _vm.formRules.customerName
                                                    .name,
                                                placeholder: _vm.$t(
                                                  _vm.formRules.customerName
                                                    .placeholder
                                                ),
                                                showSearch: true,
                                                allowClear: true,
                                                loading: _vm.loadingCustomer,
                                                "option-filter-prop":
                                                  "children",
                                                "filter-option":
                                                  _vm.filterOption
                                              },
                                              on: {
                                                search: function(value) {
                                                  return _vm.getCustomer(value)
                                                }
                                              }
                                            },
                                            _vm._l(
                                              _vm.dataListCustomer,
                                              function(data, index) {
                                                return _c(
                                                  "a-select-option",
                                                  {
                                                    key: index,
                                                    attrs: { value: data.id }
                                                  },
                                                  [
                                                    _c(
                                                      "a-tooltip",
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "title" },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "" +
                                                                    data.fullName
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              "" + data.fullName
                                                            ) +
                                                            " "
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.salesType.label
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value:
                                                    _vm.formRules.salesType
                                                      .decorator,
                                                  expression:
                                                    "formRules.salesType.decorator"
                                                }
                                              ],
                                              attrs: {
                                                name:
                                                  _vm.formRules.salesType.name,
                                                placeholder: _vm.$t(
                                                  _vm.formRules.salesType
                                                    .placeholder
                                                ),
                                                "filter-option":
                                                  _vm.filterOption,
                                                allowClear: true
                                              }
                                            },
                                            _vm._l(
                                              _vm.dataListSalesType,
                                              function(item, index) {
                                                return _c(
                                                  "a-select-option",
                                                  {
                                                    key: index,
                                                    attrs: { value: item.value }
                                                  },
                                                  [
                                                    _c(
                                                      "a-tooltip",
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "title" },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.value
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.value) +
                                                            " "
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.status.label
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value:
                                                    _vm.formRules.status
                                                      .decorator,
                                                  expression:
                                                    "formRules.status.decorator"
                                                }
                                              ],
                                              attrs: {
                                                name: _vm.formRules.status.name,
                                                placeholder: _vm.$t(
                                                  _vm.formRules.status
                                                    .placeholder
                                                ),
                                                allowClear: true,
                                                "filter-option":
                                                  _vm.filterOption
                                              }
                                            },
                                            _vm._l(
                                              _vm.dataListSalesState,
                                              function(item, index) {
                                                return _c(
                                                  "a-select-option",
                                                  {
                                                    key: index,
                                                    attrs: { value: item.value }
                                                  },
                                                  [
                                                    _c(
                                                      "a-tooltip",
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "title" },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.value
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.value) +
                                                            " "
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    {
                                      staticClass: "mt-2 mb-2",
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "flex-end"
                                      },
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-right": "1rem"
                                          },
                                          attrs: { type: "danger" },
                                          on: { click: _vm.clear }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(this.$t("lbl_reset")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-right": "1rem"
                                          },
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.loadingFind
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.findData(false)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(this.$t("lbl_find")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _vm.$can("create", "sales-order")
                                        ? _c(
                                            "a-button",
                                            {
                                              staticStyle: { width: "180px" },
                                              attrs: { type: "primary" },
                                              on: { click: _vm.CreateNew }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("lbl_create_new")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { staticClass: "mt-4", attrs: { span: 24 } },
                            [
                              _c(
                                "a-tabs",
                                {
                                  attrs: {
                                    type: "card",
                                    "default-active-key": "List"
                                  }
                                },
                                [
                                  _c(
                                    "a-tab-pane",
                                    {
                                      key: "List",
                                      staticClass: "bordertabs p-2",
                                      attrs: { tab: "List" }
                                    },
                                    [
                                      _c("TableCustom", {
                                        attrs: {
                                          idtable: "table1",
                                          dataSource: _vm.dataSource,
                                          columns: _vm.columnsTable,
                                          onSelectChange: null,
                                          selectedRowKeys: null,
                                          scroll: {
                                            x: "calc(500px + 50%)",
                                            y: 500
                                          },
                                          paginationcustom: true,
                                          defaultPagination: false,
                                          expandedRowRender: true
                                        },
                                        on: { "on-view": _vm.handleClickEdit },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "custom-expandedRowRender",
                                            fn: function(ref) {
                                              var record = ref.record
                                              return [
                                                _c(
                                                  "a-col",
                                                  {
                                                    staticClass: "mt-4",
                                                    attrs: { span: 21 }
                                                  },
                                                  [
                                                    _c("TableCustom", {
                                                      attrs: {
                                                        columns:
                                                          _vm.columnsTableNested,
                                                        "data-source":
                                                          record.salesOrderLines,
                                                        scroll: {
                                                          x:
                                                            "calc(300px + 50%)",
                                                          y: 500
                                                        },
                                                        defaultPagination: {
                                                          showTotal: function(
                                                            total
                                                          ) {
                                                            return _vm.$t(
                                                              "lbl_total_items",
                                                              { total: total }
                                                            )
                                                          }
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { staticClass: "mt-2", attrs: { span: 12 } },
                            [
                              _c("Pagination", {
                                attrs: {
                                  total:
                                    _vm.totalElements === 0
                                      ? 1
                                      : _vm.totalElements,
                                  pageSizeSet: _vm.limit,
                                  idPagination: "pagination1"
                                },
                                on: {
                                  "response-pagesize-change":
                                    _vm.responsePageSizeChange,
                                  "response-currentpage-change":
                                    _vm.responseCurrentPageChange
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "mt-3",
                              attrs: { span: 12, align: "end" }
                            },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticStyle: { "font-size": "13px" },
                                  attrs: { color: "grey" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_total_found")) +
                                      " : " +
                                      _vm._s(_vm.totalElements) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }